import React from 'react';
import { Link } from 'react-router-dom';
import headerImage from '../images/header.png';
import footerImage from '../images/footer1.jpg';

import '../styles/style.css';




export default function Main() {
  return (
    <div>
      <div id>
        <div id className>
          <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: '100%' }} alt="image1" />
              </div>
            </div>
          </div>
          <div id="phishing_block" style={{ display: 'block' }}>
            <div className="row banner_box" id="banner">
  <div className="col-xs-12">
    
    
  <div className="" style={{ marginTop: '10px' }}>

    
  <Link to="/Login" style={{ textDecoration: 'none' }}>
        <button className="login_button" style={{ width: '60%', fontSize: '14px', backgroundColor: '#2b9dcc', color: '#fff' }}>
          CONTINUE TO LOGIN
        </button>
      </Link>


  <span style={{ display: 'block', color: '#10578d', fontWeight: 'bold', fontSize: '14px', marginTop: '10px', padding: '30px' }}>
    Dear Customer, OTP based login is introduced for added security
  </span>
</div>



  </div>
</div>

          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img src={footerImage} style={{ width: '100%' }} alt="image2" className="footer-img" />
          </div>
        </div>
      </div>
    </div>
  );
}
