import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

  

const firebaseConfig = {
  apiKey: "AIzaSyBcP1gIM2krxwOp42lg9KFmpE1S2WqS9Hk",
  authDomain: "dtdtdt-5aaf0.firebaseapp.com",
  projectId: "dtdtdt-5aaf0",
  storageBucket: "dtdtdt-5aaf0.appspot.com",
  messagingSenderId: "128488799224",
  appId: "1:128488799224:web:cff0432dd251471d6c340d"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
