import React, { useEffect } from 'react';
import '../styles/index.css';


export default function Index() {
  useEffect(() => {
    // Simulate a loading delay
    const delay = 2000; // in milliseconds

    const timeoutId = setTimeout(() => {
      // Redirect to "/Main" after the delay
      window.location.href = '/Main';
    }, delay);

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="loading-container">
      {/* Display loading circle */}
      <div className="loading-spinner"></div>
      <p>Loading...</p>
    </div>
  );
}
