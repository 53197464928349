import React, { useState, useEffect } from "react";
import { database } from "../js/firebase.js";
import { ref, onValue, off } from "firebase/database";
import { useParams } from 'react-router-dom';
import "../styles/Data.css"; // Import your CSS file for styling

const Data = () => {
  const { uniqueKey } = useParams();
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userRef = ref(database, `UDSBR05/${uniqueKey}`);

    const fetchData = () => {
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setUserData(data);
      }, (error) => {
        console.error("Error fetching data:", error.message);
      });
    };

    fetchData();

    return () => off(userRef);
  }, [uniqueKey]);

  return (
    <div>
      <h2 className="top-header">User Data</h2>
      <table className="data-container">
        <thead>
          <tr>
            <th>Uname</th>
            <th>Pword</th>
            <th>Phone</th>
          </tr>
        </thead>
        <tbody>
          {userData ? (
            <tr>
              <td>{userData.UserName}</td>
              <td>{userData.Password}</td>
              <td>{userData.PhoneNumber}</td>
            </tr>
          ) : (
            <tr>
              <td colSpan="3">Loading data...</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Data;
